import React from 'react';
import { Puff } from  'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loaders = () => {
  return (
    <>
    <div className='loader_site_outer'>
      
    <div className='loader_site'>
    <Puff
  visible={true}
  height="300"
  width="300"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
/>
</div>

</div>
</>
  )
}

export default Loaders