import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const real_time_data = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    real_track_data: (state, action) => {
        state.value = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {real_track_data} = real_time_data.actions

export default real_time_data.reducer