import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { Home } from "./pages/Home/Home";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { MyProfile } from "./pages/MyProfile/MyProfile";
import ProtectedRoute from "./pages/Login/ProtectedRoute";
import { MusicSummary } from "./pages/Home/MusicSummary";
import { LanguageDistribution } from "./pages/DataSets/MusicSummary/LanguageDistribution";
import { GenreDistribution } from "./pages/DataSets/MusicSummary/GenreDistribution";
import { EnergyDistribution } from "./pages/DataSets/MusicSummary/EnergyDistribution";
import { TopArtistsDistribution } from "./pages/DataSets/MusicSummary/TopArtistsDistribution";
import { YearDistribution } from "./pages/DataSets/MusicSummary/YearDistribution";
import { BpmDistribution } from "./pages/DataSets/MusicSummary/BpmDistribution";
import { ZoneWiseRegion } from "./pages/DataSets/ZoneWiseRegion";
import { StoreDetails } from "./pages/DataSets/StoreDetails/StoreDetails";
import { RealTimeStores } from "./pages/DataSets/RealTimeStores/RealTimeStores";
import { DayWiseReport } from "./pages/DataSets/DayWiseReport/DayWiseReport";
import { TopPerformance } from "./pages/DataSets/PerformanceStores/TopPerformance";
import { TopPerformingDealers } from "./pages/DataSets/PerformanceStores/TopPerformingDealers";
import { LowPerformingDealers } from "./pages/DataSets/PerformanceStores/LowPerformingDealers";
import { WorstPerformance } from "./pages/DataSets/PerformanceStores/WorstPerformance";
import { MonthlySummary } from "./pages/DataSets/MonthlySummary/MonthlySummary";
import { OnDemandSpot } from "./pages/DataSets/OnDemandSpot/OnDemandSpot";
import { MonthlyAverage } from "./pages/DataSets/MonthlyAverage/MonthlyAverage";
import OnlineOfflineStores from "./pages/DataSets/OnlineOfflineStores/OnlineOfflineStores";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<ProtectedRoute component={Home} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/my-profile"
          element={<ProtectedRoute component={MyProfile} />}
        />
        <Route
          path="/zone-wise"
          element={<ProtectedRoute component={ZoneWiseRegion} />}
        />
        <Route
          path="/store-details"
          element={<ProtectedRoute component={StoreDetails} />}
        />{" "}
        <Route
          path="/music-summary"
          element={<ProtectedRoute component={MusicSummary} />}
        />
        <Route
          path="/language"
          element={<ProtectedRoute component={LanguageDistribution} />}
        />
        <Route
          path="/genre"
          element={<ProtectedRoute component={GenreDistribution} />}
        />
        <Route
          path="/energy"
          element={<ProtectedRoute component={EnergyDistribution} />}
        />
        <Route
          path="/bpm"
          element={<ProtectedRoute component={BpmDistribution} />}
        />
        <Route
          path="/year"
          element={<ProtectedRoute component={YearDistribution} />}
        />
        <Route
          path="/top30"
          element={<ProtectedRoute component={TopArtistsDistribution} />}
        />
        <Route
          path="/real-time-stores"
          element={<ProtectedRoute component={RealTimeStores} />}
        />
        <Route
          path="/online-offline-stores"
          element={<ProtectedRoute component={OnlineOfflineStores} />}
        />
        <Route
          path="/day-wise-reports"
          element={<ProtectedRoute component={DayWiseReport} />}
        />
        <Route
          path="/top-performing-store"
          element={<ProtectedRoute component={TopPerformingDealers} />}
        />
        <Route
          path="/worst-performing-store"
          element={<ProtectedRoute component={LowPerformingDealers} />}
        />
        <Route
          path="/monthly-summary"
          element={<ProtectedRoute component={MonthlySummary} />}
        />
        <Route
          path="/on-demand-spot"
          element={<ProtectedRoute component={OnDemandSpot} />}
        />
        <Route
          path="/average-playout"
          element={<ProtectedRoute component={MonthlyAverage} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
