import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { Table } from 'react-bootstrap';

function Popup(props) {
 //console.log(props)

  return (
    <>
      <Modal show={props._show} onHide={props.handleClose}>
        <Modal.Header closeButton >
          <Modal.Title className='text-primary'>{props.dealer}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover variant="dark" >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Played At Date-Time</th>
                          <th>As Played</th>
                        </tr>
                      </thead>
                      {props?.additionalData?.length !== 0 ? (
                        <>
                          {props?.additionalData?.map((item, index) => {
                            if (item.length === 0) {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                  </tr>
                                </tbody>
                              );
                            } else {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {moment(item.played_at_date_time).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </td>
                                    <td>{item.as_played}</td>
                                  </tr>
                                </tbody>
                              );
                            }
                          })}
                        </>
                      ) : null}
                    </Table>
                    </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup