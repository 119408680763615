import React, { useEffect, useState, useMemo } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import TableContainer from "./TableContainer";
import { SelectColumnFilter } from "./filter";
import { CSVLink } from "react-csv";
import SingleFilter from "./SingleFilter";
import {  useDispatch } from 'react-redux'
import {dealerDetailsData} from '../../../redux/slices/dealerDetails';
import { HeaderData } from "../../../redux/slices/categoryHeader";
import Tables from "./Table";
import DWreport from "../DayWiseReport/DWreport";
import Button from "react-bootstrap/Button";

import DetailWiseReportTable from "./DetailWiseReportTable";
import Loaders from "./Loaders";
//import CommonFilter from "./CommonFilter";
export const StoreDetails = () => {
  const [tableData, setTableData] = useState([]);
  const[isLoading,setIsLoading]=useState(true)
  
  let dispatch=useDispatch();
 // console.log(dispatch)
//console.log(tableData)
  useEffect(() => {
    const getStoreData = async () => {
      await apiInstance
        .get("/api/v1/stores/store-list", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setTableData(response.data);
          if(response){
            setIsLoading(false)
          }
          dispatch(dealerDetailsData(response.data))
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    };
    getStoreData();
  }, []);
  const handleShow = (cell) => {
    console.log(cell?.row?.original);
}

  const columns = useMemo(
    () => [
      {
        Header: "S.no.",
        id: "row",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        width: 60,
        disableGlobalFilter: true,
      },
      {
        Header: "Dealers Detail",
        accessor: "name",
        Filter: SelectColumnFilter,
        // filter: "equals",
        disableFilters: true,
        // Cell: props => <button className="btn1" onClick={() => handleShow(props)}>Details</button>,
      },
      {
        Header: "Dealer Code",
        accessor: "store_code",
        Filter: SelectColumnFilter,
        //filter: "equals",
        disableFilters: true,
      },
      {
        Header: "APP ID",
        accessor: "store_username",
        Filter: SelectColumnFilter,
        // filter: "equals",
        disableFilters: true,
      },
      {
        Header: "City",
        accessor: "city",
        Filter: SelectColumnFilter,
        // filter: "equals",
        disableFilters: true,
      },
      {
        Header: "Zone",
        accessor: "zone_name",
        Filter: SelectColumnFilter,
        //filter: "equals",
        disableFilters: true,
      },
      {
        Header: "Region",
        accessor: "region_name",
        Filter: SelectColumnFilter,
        //filter: "equals",
        disableFilters: true,
      },
      {
        Header: "Network Type",
        accessor: "network_type",
        Filter: SelectColumnFilter,
        //filter: "equals",
        disableFilters: true,
      },
      {
        Header: "Category",
        accessor: "category",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
    ],
    []
  );
useEffect(()=>{
  dispatch(HeaderData(columns));
},[columns])
console.log(columns)
  const headers = [
    { label: "Region", key: "region_name" },
    { label: "Zone", key: "zone_name" },
    { label: "City", key: "city" },
    { label: "Dealer Name", key: "name" },
    { label: "DLR Code", key: "store_code" },
  ];

  const csvReport = {
    filename: "Store Details Report.csv",
    headers: headers,
    data: tableData,
  };

  return (
    <>
    <Sidebar />
      {
        isLoading ?         <Loaders/> :<span>
          
      <main className="content">
        <NavbarTop />
        <Container className="">
          <h4 className="text-center mb-4 heading-h4-home">Dealer Details</h4>
          <Row>
           
            <Col style={{marginRight:'-200px'}}>
              <CSVLink {...csvReport} className="export-to-csv-button p-1 download_btn">
                Download Report
              </CSVLink>
            </Col>
          </Row>

          <div className="bg-card-shadow my-3">
            <Card>
              {/* <TableContainer columns={columns} data={tableData} /> */}
              {/* {console.log(columns)} */}
              <DetailWiseReportTable mockData={tableData } columns={columns} />
              {/* <Tables/> */}
            </Card>
          
          </div>
        
        </Container>
        {/* <Loaders/> */}
      </main>
        </span>
      }
      
     
      
    </>
  );
};
