import React, { useEffect, useState, useMemo } from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import { SelectColumnFilter } from "../StoreDetails/filter";
import { CSVLink } from "react-csv";
import { NavbarTop } from "../../../components/NavbarTop";
import { Container, Row, Col, Card } from "react-bootstrap";
import DetailWiseReportTable from "../StoreDetails/DetailWiseReportTable";

export const BpmDistribution = ({ showTable }) => {
  const [bPMDDistributionData, getBPMDDistributionData] = useState([]);

  useEffect(() => {
    const getGenreDistributionData = async () => {
      await apiInstance
        .get("/api/v1/music/music-bpmd-distribution", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          getBPMDDistributionData(response.data.genres);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getGenreDistributionData();
  }, []);

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "BPM Distribution",
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data: bPMDDistributionData.map((item) => ({
          name: item.bpmRange,
          y: item.total_tracks,
        })),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.no.",
        id: "row",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters:true,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "BPM Range",
        accessor: "bpmRange",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters:true,
        //filter: "equals",
      },
      {
        Header: "Total Tracks",
        accessor: "total_tracks",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "S Order",
        accessor: "sorder",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
    ],
    []
  );

  const headers = [
    { label: "BPMD Range", key: "bpmRange" },
    { label: "Total Tracks", key: "total_tracks" },
    { label: "S Order", key: "sorder" },
  ];

  const csvReport = {
    filename: "BPM Distribution Report.csv",
    headers: headers,
    data: bPMDDistributionData,
  };

  return (
    <>
      {showTable === false ? (
        <PieChart highcharts={Highcharts} options={options} />
      ) : (
        <>
          <Sidebar />
          <main className="content">
            <NavbarTop />
            <Container>
              <PieChart highcharts={Highcharts} options={options} />

              {showTable === false ? null : (
                <div className="p-5 m-5">
                  <Row>
                    <Col>
                      <CSVLink
                        {...csvReport}
                        className="export-to-csv-button p-1"
                      >
                        Download Report
                      </CSVLink>
                    </Col>
                  </Row>
                  <div className="bg-card-shadow my-3">
                    <Card>
                      {/* <TableContainer
                        columns={columns}
                        data={bPMDDistributionData}
                      /> */}
                      <DetailWiseReportTable
                      columns={columns}
                      mockData={bPMDDistributionData}
                      />
                    </Card>
                  </div>
                </div>
              )}
            </Container>
          </main>
        </>
      )}
    </>
  );
};
