import React from "react";
import { Sidebar } from "../../components/Sidebar";
import { Card, Row, Col } from "react-bootstrap";
import { LanguageDistribution } from "../DataSets/MusicSummary/LanguageDistribution";
import { GenreDistribution } from "../DataSets/MusicSummary/GenreDistribution";
import { EnergyDistribution } from "../DataSets/MusicSummary/EnergyDistribution";
import { BpmDistribution } from "../DataSets/MusicSummary/BpmDistribution";
import { NavbarTop } from "../../components/NavbarTop";

export const MusicSummary = ({ showTable }) => {
  return (
    <>
      <Sidebar />
      <main className="content">
        <NavbarTop />
        <h4 className="text-center mb-2 heading-h4-home">Music Summary</h4>
        <Row className="my-5">
          <Col md={6}>
            <Card>
              <LanguageDistribution showTable={false} />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <GenreDistribution showTable={false} />
            </Card>
          </Col>
          <Col md={6} className="my-4">
            <Card>
              <EnergyDistribution showTable={false} />
            </Card>
          </Col>
          <Col md={6} className="my-4">
            <Card>
              <BpmDistribution showTable={false} />
            </Card>
          </Col>
        </Row>
      </main>
    </>
  );
};
