import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import { Nav, Badge, Image, Button, Accordion, Navbar } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;

  const [show, setShow] = useState(false);
  const onCollapse = () => setShow(!show);
  const showClass = show ? "show" : "";

  function logout(){
    localStorage.clear();
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";


    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon"></span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <RiLogoutCircleRLine />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to="/home">
          {/* <Image src="" className="navbar-brand-light" /> */}
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
          className="taggle_btn"
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>

      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src="https://demo.themesberg.com/volt-react-dashboard/static/media/profile-picture-3.9a250e5c.jpg"
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {localStorage.getItem("full_name")}</h6>
                  <Button
                     onClick={logout}
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to="/"
                    className="text-dark"
                  >
                    <RiLogoutCircleRLine/> Sign Out 
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FaTimes />
              </Nav.Link>
            </div>

            <Nav className="flex-column pt-3 pt-md-0">
              <img
                src="https://www.radiowalla.in/images/rw-logo.png"
                className="img-fluiid na-b0-logo mb-5"
                alt="Radiowalla.in"
              />
              <CollapsableNavItem
                eventKey="dashboard/"
                title="Dashboard"
                // icon={faTable}
              >
                <NavItem title="Overview" link="/home" />
                <NavItem title="Dealers Distribution" link="/zone-wise" />
                {/* <NavItem title="Start-time" link="/start-time" />
                <NavItem title="End-time" link="/end-time" /> */}
                <NavItem
                  title="Top Performing Dealers"
                  link="/top-performing-store"
                />
                <NavItem
                  title="Low Performing Dealers"
                  link="/worst-performing-store"
                />
                <NavItem title="Real-time Stores" link="/online-offline-stores" />
                {/* <NavItem title="Average Playout" link="/average-playout" /> */}
              </CollapsableNavItem>
              <CollapsableNavItem
                title="Music Summary"
                // icon={faTable}
              >
                <NavItem title="Overview" link="/music-summary" />
                <NavItem title="Language" link="/language" />
                <NavItem title="Genre" link="/genre" />
                <NavItem title="Energy" link="/energy" />
                <NavItem title="BPM" link="/bpm" />
                <NavItem title="Year" link="/year" />
                <NavItem title="Top 30 Artists" link="/top30" />
              </CollapsableNavItem>
              <NavItem title="Dealer Details" link="/store-details" />
              <NavItem title="Real-time Data" link="/real-time-stores" />
              <NavItem title="Daywise Reports" link="/day-wise-reports" />
              <NavItem title="Monthly Summary" link="/monthly-summary" />
              <NavItem title="On Demand Spot" link="/on-demand-spot" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
