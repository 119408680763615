import React from 'react';
import '../../../components/commoncss/common.css'

const GlobalFilter = ({filter,setFilter}) => {
  return (
    <span className='gbf'>
      <input value={filter || ''} onChange={(e=>setFilter(e.target.value))} placeholder='Search...' className='serachFilterStyle' />
    </span>
  )
}

export default GlobalFilter
