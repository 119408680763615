import React, { useEffect, useState } from "react";
import { NavbarTop } from "../../components/NavbarTop";
import "../../App.css";
import { Sidebar } from "../../components/Sidebar";
import { ZoneWiseRegion } from "../DataSets/ZoneWiseRegion";
import { TopPerformance } from "../DataSets/PerformanceStores/TopPerformance";
import { WorstPerformance } from "../DataSets/PerformanceStores/WorstPerformance";
import Loaders from "../DataSets/StoreDetails/Loaders";
import OnlineOfflineStores, { OnlineOfflineStoresGraph } from "../DataSets/OnlineOfflineStores/OnlineOfflineStoresGraph";


export const Home = () => {
  const[isLoading,setIsLoading]=useState(true);
  useEffect(()=>{
    setTimeout(() => {
      setIsLoading(false)
    }, 3000);
  },[])

  return (
    <>
      <Sidebar />
      {
        isLoading?<Loaders/>:  <main className="content">
        <NavbarTop />
        <h4 className="text-center my-5 heading-h4-home">Dealers Distribution</h4>
        <ZoneWiseRegion overview="overview" />
        <h4 className="text-center my-5 heading-h4-home">Top Performers</h4>
        <TopPerformance overview="overview" />
        <h4 className="text-center my-5 heading-h4-home">Low Performers</h4>
        <WorstPerformance overview="overview" />
        <h4 className="text-center my-5 heading-h4-home">Real Time Stores</h4>
        <OnlineOfflineStoresGraph overview="overview" />
      </main>
      }
     
    </>
  );
};
