import React, { useEffect } from 'react'
import { useTable, useGlobalFilter, usePagination, useFilters ,useSortBy} from 'react-table';
import GlobalFilter from './GlobalFilter';
import { Scrollbar } from 'react-scrollbars-custom';
import {  useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import {Styles} from '../DayWiseReport/TableStyles';
import { useDispatch } from 'react-redux';
import { real_track_data } from '../../../redux/slices/realtimeSlice';
import apiInstance from '../../../utils/api';
import { handleShow } from '../RealTimeStores/RealTimeStores';
import { loading } from '../../../redux/slices/loadingSlice';
import Button from "react-bootstrap/Button";

function DetailWiseReportTable({ columns, mockData }) {
  const dispatch=useDispatch()
  const data = React.useMemo(() => [...mockData], [mockData])

  //   const columns=React.useMemo(() => props?.mockData[0]?Object.keys(props?.mockData[0]).slice(1).map((key)=>{
  //     return{
  //         Header: key.toUpperCase(),
  //         accessor: key,
  //     }
  //   }):[], [props.mockData])

let _day = new Date().getDate();
let _mn = new Date().getMonth() + 1;
let _yr = new Date().getFullYear();

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  setFilter,
  prepareRow,
  pageOptions,
  setPageSize,
  gotoPage,
  pageCount,
  state,
  setGlobalFilter,
} = useTable(
  { columns, data },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useBlockLayout,
  useSticky
);

const { globalFilter, pageIndex, pageSize } = state;
const generateSortingIndicator = (column) => {
  return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
};
const getId = (data) => {
  // console.log(`${_day}-${_mn}-${_yr}`)

  const getRealTimeTrackData = async () => {
    await apiInstance
      .get(
        `/api/v1/stores/real-time-d-l/${data?.row?.original?.store?.store_username}/${_day}-${_mn}-${_yr}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        dispatch(real_track_data(response.data));
        dispatch(loading(false));
        if (response) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getRealTimeTrackData();
  handleShow();
};
  
  const setColumnFilterValue = (columnId, filterValue) => {
    setFilter(columnId, filterValue);
  };

return (
  <div className="mb-3">
    <span className="_page_limit">
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <span className="fw-bold">
        Show{" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          id="page_limit"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </span>
    </span>
    <div style={{ overflowX: "auto" }}>
      <Styles>
        <table {...getTableProps()} className="table sticky" id="customers">
          <thead className="header">
            {Object.values(headerGroups)?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup?.headers?.map((column) => (
                  <th
                    className="th"
                    {...column?.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column?.render("Header")}
                    {generateSortingIndicator(column)}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="body">
            {page.map((row) => {
              prepareRow(row);
              console.log("row : ", row);
              return (
                <tr {...row.getRowProps()} className="tr">
                  {row?.cells?.map((cell) => {

                    const { column, value } = cell;
                    const { id } = column;

                    if (id === "columnName" && value === "desiredValue") {
                      setColumnFilterValue(id, "filterValue");
                    }
                    
                    if (cell.column.Header == "As Played Log") {
                      return (
                        <td className="td" {...cell.getCellProps()}>
                          <Button
                            variant="primary"
                            onClick={() => {
                              getId(cell);
                            }}
                          >
                            View
                          </Button>
                        </td>
                      );
                    }
                    return (
                      <td className="td" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Styles>
    </div>
    <div className="pagination_btn ">
      <span className="mt-2 me-2">
        <span className="">Page </span>
        <strong>
          <span className="text-success">{pageIndex + 1}</span> of{" "}
          {pageOptions.length}
        </strong>
      </span>
      <div className="button_flex">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className={canPreviousPage ? "_buttonNext" : "buttonPrev"}
        >
          {"<<"}
        </button>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={canPreviousPage ? "_buttonNext" : "buttonPrev"}
        >
          Prev
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={canNextPage ? "_buttonNext" : "buttonPrev"}
        >
          Next
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className={canNextPage ? "_buttonNext" : "buttonPrev"}
        >
          {">>"}
        </button>
      </div>
    </div>
  </div>
);
}
export default DetailWiseReportTable