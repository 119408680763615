import React, { useEffect, useState, useMemo } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Container, Row, Col, Modal, Table } from "react-bootstrap";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Loaders from "../StoreDetails/Loaders";
import DWreport from "../DayWiseReport/DWreport";
import { SelectColumnFilter } from "../StoreDetails/filter";
import OnDemandSpotTable from "./OnDemandSpotTable";
import { CSVLink } from "react-csv";

export const OnDemandSpot = (props) => {
  const [tableData, setTableData] = useState([]);
  const [dcfdC, setDC] = useState([]);

  const [columns, setColumns] = useState([
    {
      Header: "S.No.",
      id: "row",
      Cell: ({ row }) => <span>{row.index + 1}</span>,
      width: 60,
      sticky: "left",
      disableGlobalFilter: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "STORE_CODE ",
      accessor: "store_code",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "CATEGORY",
      accessor: "category",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "NETWORK_TYPE",
      accessor: "network_type",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
  ]);

  const [startDate, setStartDate] = useState(moment().subtract(2, "days"));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [additionalData, setAdditionalData] = useState([]);
  const [rowID, setRowID] = useState("");
  const [cellDate, setCellDate] = useState("");
  const [additionalDealerName, setAdditionalDealerName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [storeHeader, setStoreHeader] = useState([]);

  const [show, setShow] = useState(false);
  const [state, setState] = useState(moment().subtract(3, "days"));
  const handleCallback = (start, end) => {
    setState({ start, end });
  };

  const handleClose = () => {
    setAdditionalData([]);
    setAdditionalDealerName("");
    setShow(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    const getOnDemandSpotData = async () => {
      await apiInstance
        .get("/api/v1/stores/on-demand-analytics", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          let tmp = response.data;
          if (response) {
            setIsLoading(false);
          }

          tmp.forEach((e) => {
            delete e.p_type;
          });

          let dColumns = Object.keys(tmp[0])?.map((key) => {
            return {
              Header: key.split("_").join(" "),
              accessor: key,
              disableSortBy: true,
              disableFilters: true,
            };
          });

          const serialNumber = {
            Header: "S.no.",
            id: "row",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
          };

          for (let project of tmp) {
            if (project.network_type === "3") {
              project.network_type = "AD-3rd Party";
            } else if (project.network_type === "1") {
              project.network_type = "AMD";
            }
          }

          dColumns.map((item) => {
            if (item.Header === "id") {
              item["show"] = false;
              return item;
            }
          });

          const newColumns = Object.keys(tmp[0])
            .slice(1)
            .map((key, index) => {
              if (index < 4) {
                return {
                  Header: key.toUpperCase(),
                  accessor: key,
                  sticky: "left",
                  Filter: SelectColumnFilter,
                  disableFilters: true,
                };
              } else {
                return {
                  Header: key.toUpperCase(),
                  accessor: key,
                  Filter: SelectColumnFilter,
                  disableFilters: true,
                };
              }
            });

          newColumns.unshift({
            Header: "S.no.",
            id: "row",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
            width: 60,
            sticky: "left",

            disableGlobalFilter: true,
          });

          console.log("newColumns : ", newColumns);
          setColumns(newColumns);

          setDC([serialNumber, ...dColumns]);
          setTableData(tmp);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
    getOnDemandSpotData();
  }, []);

  // const handleOnDemandClick = (rowID, cellDate) => {
  //   handleShow();
  //   apiInstance
  //     .get(
  //       `/api/v1/stores/on-demand-analytics/details?date=${cellDate}&store_id=${rowID}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access")}`,
  //         },
  //       }
  //     )
  //     .then((re) => {
  //       setAdditionalData(re.data.data);
  //       setAdditionalDealerName(re.data.dealer_name);
  //     })
  //     .catch((er) => {
  //       console.log(er);
  //     });
  // };

  const handleSelectedDate = (event, picker) => {
    setIsLoading(true);
    apiInstance
      .get(
        `/api/v1/stores/on-demand-analytics?start_date=${moment(
          picker.startDate
        ).format("YYYY-MM-DD")}&end_date=${moment(picker.endDate).format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        let tmp = response.data;
        if (response) {
          setIsLoading(false);
        }

        tmp.forEach((e) => {
          delete e.p_type;
        });

        let dColumns = Object.keys(tmp[0])?.map((key) => {
          return {
            Header: key.split("_").join(" "),
            accessor: key,
            Filter: SelectColumnFilter,
            filter: "equals",
          };
        });

        const serialNumber = {
          Header: "S.no.",
          id: "row",
          Cell: ({ row }) => <span>{row.index + 1}</span>,
        };

        for (let project of tmp) {
          if (project.network_type === "3") {
            project.network_type = "AD-3rd Party";
          } else if (project.network_type === "1") {
            project.network_type = "AMD";
          }
        }

        dColumns.map((item) => {
          if (item.Header === "id") {
            item["show"] = false;
            return item;
          }
        });

        const newColumns = Object.keys(tmp[0])
          .slice(1)
          .map((key, index) => {
            if (index < 4) {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                sticky: "left",
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            } else {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            }
          });

        newColumns.unshift({
          Header: "S.no.",
          id: "row",
          Cell: ({ row }) => <span>{row.index + 1}</span>,
          width: 60,
          sticky: "left",
          disableGlobalFilter: true,
        });

        console.log("newColumns : ", newColumns);
        setColumns(newColumns);

        setDC([serialNumber, ...dColumns]);

        setTableData(tmp);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const csvReport = {
    filename: "Day Wise Report.csv",
    // headers: headers,
    data: tableData,
  };

  return (
    <>
      <Sidebar />
      {isLoading ? (
        <Loaders />
      ) : (
        <main className="content">
          <NavbarTop />
          <Container>
            <h4 className="text-center mb-2 heading-h4-home">On Demand Spot</h4>
            <Row>
              <Col>
                <CSVLink
                  {...csvReport}
                  className="export-to-csv-button p-1 download_btn"
                >
                  Download Report
                </CSVLink>
              </Col>
            </Row>
            <div className="my-3">
              {tableData.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                >
                  <p style={{ fontSize: "40px" }}>Error While Fetching Data</p>
                </div>
              ) : (
                <>
                  <Row className="my-3">
                    <Col md={5}>
                      <DateRangePicker
                        onCallback={handleCallback}
                        onApply={handleSelectedDate}
                        initialSettings={{
                          startDate: state?.start?.format("MM/DD/YYYY (dddd)"),
                          endDate: state?.end?.format("MM/DD/YYYY (dddd)"),
                          ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 3 Days": [
                              moment().subtract(2, "days"),
                              moment(),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          className="form-control col-4"
                        ></input>
                      </DateRangePicker>
                    </Col>
                  </Row>
                  {/* <TableContainer
                  columns={dcfdC}
                  data={tableData}
                  changeID={(rowID) => setRowID(rowID)}
                  changeCellDate={(cellDate) => setCellDate(cellDate)}
                  onclick={handleOnDemandClick}
                /> */}
                  <OnDemandSpotTable mockData={tableData} columns={columns} />

                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-dialog-scrollable"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="text-center">
                        {additionalDealerName}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Played At Date-Time</th>
                            <th>As Played</th>
                          </tr>
                        </thead>
                        {additionalData.length !== 0 ? (
                          <>
                            {additionalData?.map((item, index) => {
                              if (item.length === 0) {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>0</td>
                                      <td>0</td>
                                      <td>0</td>
                                    </tr>
                                  </tbody>
                                );
                              } else {
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {moment(
                                          item.played_at_date_time
                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                      </td>
                                      <td>{item.as_played}</td>
                                    </tr>
                                  </tbody>
                                );
                              }
                            })}
                          </>
                        ) : null}
                      </Table>
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </div>
          </Container>
        </main>
      )}
    </>
  );
};
