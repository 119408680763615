import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';


function Tables() {
    const categoryHeaderData = useSelector((state) => state?.categoryHeader?.value);
    const dealersDetails = useSelector((state) => state?.dealerSlice?.value);
    const filterData = useSelector((state) => state?.filter?.value);
    console.log(filterData)

    //   console.log(dealersDetails)
    //   console.log(categoryHeaderData)
    return (
        <Table   className='' id='customers'>
            <thead>
                <tr>
                    {
                        Object.values(categoryHeaderData)?.map((value) => {
                            // console.log(value)
                            return <th>{value.Header}</th>

                        })
                    }


                </tr>
            </thead>

            {Object.values(dealersDetails).filter((item) => {
                if (filterData == null) {

                }
                else if (filterData == "All") {
                    return item
                } else if (item?.name?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                } else if (item?.store_code?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                }
                else if (item?.store_username?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                } else if (item?.city?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                } else if (item?.zone_name?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                } else if (item?.network_type?.toLowerCase().includes(filterData?.toLowerCase())) {
                    return item
                }
            }).map((item) => {
                return <tbody>
                    <tr>
                        <td>{item?.name}</td>
                        <td>{item?.store_code}</td>
                        <td>{item?.store_username}</td>
                        <td>{item?.city}</td>
                        <td>{item?.zone_name}</td>
                        <td>{item?.region_name}</td>
                        <td>{item?.network_type}</td>
                    </tr>
                </tbody>
            })
            }


        </Table>
    );
}

export default Tables;