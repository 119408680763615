import React, { useEffect, useState, useMemo } from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import Exporting from "highcharts/modules/exporting";

export const OnlineOfflineStoresGraph = () => {
Exporting(Highcharts);

  const [realTimeStoresData, setRealTimeStoresData] = useState([]);

  useEffect(() => {
    const getRealTimeStoresData = async() => {
      await apiInstance
        .get("/api/v1/music/online-offline-stores", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          console.log("response.data : " , response.data);
          setRealTimeStoresData(response.data);
          console.log(realTimeStoresData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getRealTimeStoresData();
  }, []);

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "Online-Offline Dealers",
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data:
          [
            {
              name: "online",
              y: realTimeStoresData.realtime 
            },
            { 
              name: "offline",
              y: (realTimeStoresData.total-realTimeStoresData.realtime)
            }
          ]
        ,
      },
    ],
    credits: {
      enabled: false,
    },
  };
   
    return (
      <>
        {
          <PieChart highcharts={Highcharts} options={options} />
        }
      </>
    )                                            
};
