import React from "react";
import { Navigate, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  if (!isAuthenticated) return <Navigate to="/" />;
  return <Component />;
}

export default ProtectedRoute;
