import React, { useState } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import "../../App.css";
import { NavbarTop } from "../../components/NavbarTop";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import apiInstance from "../../utils/api";
import { useNavigate } from "react-router";
import { Sidebar } from "../../components/Sidebar";

export const MyProfile = () => {
  const navigate = useNavigate();

  const [passwordView, setPasswordView] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const initialValues = {
    fullname: "",
    oldPassword: "",
    newPassword: "",
  };

  const validate = Yup.object({});

  const handleProfileChangesSubmit = async (values) => {
    await apiInstance
      .put(
        "/api/v1/change-password/",
        {
          old_password: values.oldPassword,
          new_password: values.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 403) {
          setErrorMessage(response.data.errors[0].message[0]);
        } else {
          setErrorMessage("Password changed successfully.");
          setPasswordView(false);
          navigate("/my-profile");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <NavbarTop />
        <Container>
          <Row>
            <Col md={8}>
              <Card className="card_bg">
                <Card.Body>
                  <Card.Title>General Information</Card.Title>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validate}
                    onSubmit={handleProfileChangesSubmit}
                    enableReinitialize={true}
                  >
                    {(formik) => (
                      <Form>
                        <Row>
                          <Col md={6} className="mb-3">
                            <Field
                              type="text"
                              name="fullname"
                              placeholder="Enter your full name"
                              value={localStorage.getItem("full_name")}
                            />
                          </Col>

                          <Col md={6} className="mb-3">
                            <Field
                              type="email"
                              name="email"
                              placeholder="Enter your email"
                              value={localStorage.getItem("email")}
                              readOnly={true}
                              disabled
                            />
                          </Col>
                        </Row>

                        {passwordView === true ? (
                          <Row>
                            <Col md={6} className="mb-3">
                              <Field
                                type="password"
                                name="oldPassword"
                                placeholder="Enter your old password"
                              />
                            </Col>

                            <Col md={6} className="mb-3">
                              <Field
                                type="password"
                                name="newPassword"
                                placeholder="Enter your new password"
                              />
                            </Col>
                          </Row>
                        ) : null}

                        <div>{errorMsg}</div>

                        <Row>
                          <Col className="mb-3">
                            <button onClick={() => setPasswordView(true)} className="theme_blue_btn">
                              {passwordView === true
                                ? "Cancel"
                                : "Change Password"}
                            </button>

                            <button
                              type="submit"
                              className="mx-4 theme_blue_btn"
                              //   onClick={() => handleProfileChangesSubmit(values)}
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className="card_bg">
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>
                    {localStorage.getItem("full_name")}
                  </Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    {localStorage.getItem("email")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};
