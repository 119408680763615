import React, { useEffect, useState } from 'react'
import { useTable,useGlobalFilter,usePagination,useFilters,useSortBy } from 'react-table';
import { SelectColumnFilter } from '../StoreDetails/filter';
import GlobalFilter from '../StoreDetails/GlobalFilter';
import { Scrollbar } from 'react-scrollbars-custom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table,Row, Col } from 'reactstrap';
import apiInstance from '../../../utils/api';
import moment from 'moment';
import {  useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import {Styles} from './TableStyles';
import { CSVLink } from "react-csv";
 function DWreport(props) {

  const [show, setShow] = useState(false);
  const[additionalData,setAdditionalData]=useState([])
  const[modalHeader,setModalHeader]=useState([]);
  const[loading,setLoading]=useState(true);
  const handleClose = () => {setShow(false)
        setAdditionalData(' ')
        setLoading(true)
  };
  const handleShow = () => setShow(true);
 
  let newArray=[];
  newArray.push(additionalData?.output?.timestamp);
  additionalData?.output?.map((v)=>{
    newArray.push({timestamp:moment(parseInt( v.timestamp))?.format("MMM Do YY, h:mm:ss a"),song:v.song,duration:v.duration < 59 ? `0m ${v.duration  < 10 ? `${v.duration}` : v.duration}s`: `${Math.floor(v.duration / 60) <10 ? `${Math.floor(v.duration / 60)}m` : Math.floor(v.duration / 60) }  ${ v.duration % 60 < 10 ? `0${v.duration % 60}s` : v.duration % 60}s`})
  })

 
   const data = React.useMemo(() => [...props.mockData], [props.mockData]);
   const columns = React.useMemo(() => [...props.columns], [props.columns]);
   
  // const columns=React.useMemo(() =>
  
    

  // props?.mockData[0]?Object.keys(props?.mockData[0]).map((key,index)=>{
  //   if(index<4){
  //     return{
  //       Header: key.toUpperCase(),
  //       accessor: key,
  //       sticky:'left',
  //       Filter:SelectColumnFilter,
  //       disableFilters: true,
  //   }
  //   }else{
  //     return{
  //       Header: key.toUpperCase(),
  //       accessor: key,
  //       sticky:'',
  //       Filter:SelectColumnFilter,
  //       disableFilters: true,
  //   }
  //   }
     
  // }):[], [props.mockData])

   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     page,
     nextPage,
     previousPage,
     canPreviousPage,
     canNextPage,
     prepareRow,
     pageOptions,
     setPageSize,
     gotoPage,
     pageCount,
     state,
     setGlobalFilter
   } = useTable({ columns, data },useFilters,useGlobalFilter,useSortBy,usePagination,  useBlockLayout,
    useSticky,)

   const {globalFilter,pageIndex,pageSize} = state;
   const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  const getId=(data)=>{
      let yyyy=data?.column?.Header.slice(0,4);
      let mm=data?.column?.Header.slice(5,7);
      let dd=data?.column?.Header.slice(8,10);



      setModalHeader(data)
        handleShow();
        apiInstance
          // .get(
          //   `/api/v1/stores/store-daily-play-out/details?store_username=${data?.row?.original?.app_id}&date=${data?.column?.Header}`,
          //   {
          //     headers: {
          //       Authorization: `Bearer ${localStorage.getItem("access")}`,
          //     },
          //   }
          // )
          .get(
            `/api/v1/stores/real-time-d-l/${data?.row?.original?.app_id}/${dd}-${mm}-${yyyy}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access")}`,
              },
            }
          )
          .then((re) => {
           // console.log(re)
            setLoading(false);
           setAdditionalData(re.data);
           // console.log(re)
            // setAdditionalDealerName(re.data);
          })
          .catch((er) => {
            console.log(er);
            setLoading(false);
          });
  }

  const header2=[
    {label: "Time/Date", key: "timestamp"},
    {label: "Track Name", key: "song"},
    {label: "Duration", key: "duration"},

  ]
  const csvReport2 = {
    filename: "Day Wise Tracks Report.csv",
    headers: header2,
    data: newArray.slice(1),
  };
 
   return (
    <>
     <span className='_page_limit'>
    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
    <span className='fw-bold'>Show Results: {' '}
    <select  value={pageSize} onChange={(e)=>setPageSize(e.target.value)} id='page_limit'>
      
      {
        [10,20,30,40,50].map((pageSize)=>{
         return <option key={pageSize} value={pageSize}>
          {pageSize}
         </option>
        })
       
      }
    </select>
    </span>
    </span>
    <div style={{overflowX:'auto'}}>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-primary'>{modalHeader?.row?.original?.dealer_name}</Modal.Title>
        </Modal.Header>
        {
          additionalData?.output ? <Row className='pe-4 pt-2'>
          <Col>
            <CSVLink {...csvReport2} className="export-to-csv-button p-1 download_btn">
              Download Report
            </CSVLink>
          </Col>
        </Row> : null
        }
         
        <Modal.Body style={{maxHeight:'450px',overflow:'auto'}}>
        <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>As Played</th>
                    <th>Played At Date-Time</th>
                    <th>Duration</th>                    
                  </tr>
                </thead>
                {
                  loading ? <span className='text-center text-danger fw-bold'>Please Wait While Loading......</span> :
                additionalData.length !== 0 ? (
                  <>
                
                    {
                      additionalData?.Error ? <tbody >
                      <tr>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody> :  (additionalData?.output)?.map((v, index) => {
                     
                      if (v=='') {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        );
                      } else {
                        return (
                          <tbody key={index}>
         <tr>
           <td>{moment(parseInt( v.timestamp)).format("MMM Do YY, h:mm:ss a")}</td>
           <td>{v.song}</td>
           {/* <td>{v.duration}</td> */}
           {/* <td>{v.duration < 59 ? `00 : ${v.duration  < 10 ? `0${v.duration}` : v.duration} `: `${Math.floor(v.duration / 60) <10 ? `0${Math.floor(v.duration / 60)}` : Math.floor(v.duration / 60) } : ${ v.duration % 60 < 10 ? `0${v.duration % 60}` : v.duration % 60}`}</td> */}
           <td>{v.duration < 59 ? `0m ${v.duration  < 10 ? `${v.duration}` : v.duration}s`: `${Math.floor(v.duration / 60) <10 ? `${Math.floor(v.duration / 60)}m` : Math.floor(v.duration / 60) }  ${ v.duration % 60 < 10 ? `0${v.duration % 60}s` : v.duration % 60}s`}</td>

           </tr>
       
                          </tbody>
                        );
                      }
                    })
                   
                    }
                  </>
                ) : null}
              </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
     <Styles>
     <table {...getTableProps()} className="table sticky" id='customers'>
       <thead className="header">
         {Object.values(headerGroups)?.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()} className="tr">
             {headerGroup?.headers?.map(column => (
               <th className="th"
                 {...column?.getHeaderProps(column.getSortByToggleProps())}
               >
                 {column?.render('Header')}
                 {generateSortingIndicator(column)}
                 <div>
                  {column.canFilter ? column.render('Filter'):null}
                 </div>
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="body">
         {page.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()} className="tr"> 
               {row?.cells?.map(cell => {
                 return (
                   <td className="td"
                     {...cell.getCellProps()}
                     onClick={()=>getId(cell)}
                    //  style={{
                    //    padding: '10px',
                    //    border: 'solid 1px gray',
                    //    background: 'papayawhip',
                    //  }}
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
     </Styles>
     </div>
     <div className='pagination_btn '>
      <div>
        <span className='mt-2 me-2'>
            <span className=''>
            Page{' '}
            </span>
            <strong>
                <span className='text-success'>    
                                {pageIndex + 1}
                                </span> of {pageOptions.length}
               
            </strong>
        </span>
        </div>
        <div className='button_flex'>
        <button onClick={()=>gotoPage(0)} disabled={!canPreviousPage} className={canPreviousPage?'_buttonNext': 'buttonPrev'}>{'<<'}</button>
        <button onClick={()=>previousPage()} disabled={!canPreviousPage} className={canPreviousPage?'_buttonNext': 'buttonPrev' }>Prev</button>
        <button onClick={()=>nextPage()} disabled={!canNextPage} className={canNextPage ?'_buttonNext': 'buttonPrev' }>Next</button>
        <button onClick={()=>gotoPage(pageCount-1)} disabled={!canNextPage} className={canNextPage ?'_buttonNext': 'buttonPrev' }>{'>>'}</button>
        </div> 
     </div>
     </>
   )
 }
 export default DWreport