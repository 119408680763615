import React, { useState } from "react";
import "../Login/Login.css";
import { Formik, Form, Field } from "formik";
import apiInstance from "../../utils/api";
import { Button, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  let navigate = useNavigate();

  const [errorMsg, setErrorMessage] = useState("");

  const initialValues = {
    email: "",
  };

  const validate = Yup.object({
    email: Yup.string().required(),
  });

  const handleForgotPasswordSubmit = async (values) => {
    await apiInstance
      .post(`/api/v1/password-reset/`, {
        username: values.username,
      })
      .then((response) => {
        if (response.status === 200) {
          setErrorMessage(response.data.payload.message);
          setTimeout(() => {
            navigate("/");
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setErrorMessage(
            "Something went wrong. Please try again after sometime."
          );
        } else {
          setErrorMessage(error.response.data.message[0]);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={handleForgotPasswordSubmit}
    >
      {({ values }) => (
        <div className="forgot-password-box-container">
          <div className="login-box">
            <h2>Forgot Password</h2>
            <Form>
              <div className="user-box">
                <Field
                  type="text"
                  name="username"
                  required
                  autoComplete="off"
                />
                <label>Username</label>
              </div>

              <div className="error-msg p-2">{errorMsg}</div>

              <Row>
                <Col className="forgot-pass">
                  <Link to="/">Login</Link>
                </Col>
              </Row>

              <Button
                type="submit"
                className="login-form-submit-but"
                onClick={() => handleForgotPasswordSubmit(values)}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submit
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};
