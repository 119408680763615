import React, { useEffect, useState, useMemo } from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import { SelectColumnFilter } from "../StoreDetails/filter";
import { CSVLink } from "react-csv";
import { NavbarTop } from "../../../components/NavbarTop";
import { Container, Row, Col, Card } from "react-bootstrap";
import DetailWiseReportTable from "../StoreDetails/DetailWiseReportTable";

export const TopArtistsDistribution = ({ showTable }) => {
  const [topArtistsDataSet, setTopArtistsDataSet] = useState([]);

  useEffect(() => {
    const getGenreDistributionData = async () => {
      await apiInstance
        .get("/api/v1/music/top-30-artists", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setTopArtistsDataSet(response.data.artists);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getGenreDistributionData();
  }, []);

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "Top 30 Artists",
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        colorByPoint: true,
        data: topArtistsDataSet.map((item) => ({
          name: item.artist,
          y: item.total_tracks,
        })),
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: "S.no.",
        id: "row",
        Filter: SelectColumnFilter,
        disableFilters: true,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Artist",
        accessor: "artist",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        //filter: "equals",
      },
      {
        Header: "Total Tracks",
        accessor: "total_tracks",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
    ],
    []
  );

  const headers = [
    { label: "Artist", key: "artist" },
    { label: "Total Tracks", key: "total_tracks" },
  ];

  const csvReport = {
    filename: "Top 30 Artists Report.csv",
    headers: headers,
    data: topArtistsDataSet,
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <NavbarTop />
        <Container>
          <PieChart highcharts={Highcharts} options={options} />

          {showTable === false ? null : (
            <div className="p-5 m-5">
              <Row>
                <Col>
                  <CSVLink {...csvReport} className="export-to-csv-button p-1">
                    Download Report
                  </CSVLink>
                </Col>
              </Row>
              <div className="bg-card-shadow my-3">
                <Card>
                  {/* <TableContainer columns={columns} data={topArtistsDataSet} /> */}
                  <DetailWiseReportTable
                    columns={columns}
                    mockData={topArtistsDataSet}
                  />
                </Card>
              </div>
            </div>
          )}
        </Container>
      </main>
    </>
  );
};
