import React, { useState, useEffect } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { NavbarTop } from "../../../components/NavbarTop";
import apiInstance from "../../../utils/api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import moment from "moment";

export const MonthlyAverage = () => {
  Exporting(Highcharts);
  const [monthlyAverageData, setMonthlyAverageData] = useState([]);
  const [xAxis, setXAxis] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const getMonthlyAverage = async () => {
      await apiInstance
        .get("api/v1/stores/monthly-average", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setMonthlyAverageData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getMonthlyAverage();
  }, []);

  useEffect(() => {
    let categories = [];
    let graphValues = [];

    monthlyAverageData.area_monthly_average?.map((i) => {
      categories.push(
        moment(i.store__storedailyplayout__entry_date__month, "M").format(
          "MMMM"
        )
      );
    });
    monthlyAverageData.region_monthly_average?.map((i) => {
      categories.push(
        moment(i.store__storedailyplayout__entry_date__month, "M").format(
          "MMMM"
        )
      );
    });
    monthlyAverageData.zones_monthly_average?.map((i) => {
      categories.push(
        moment(i.store__storedailyplayout__entry_date__month, "M").format(
          "MMMM"
        )
      );
    });

    monthlyAverageData?.area_monthly_average?.map((item) => {
      graphValues.push({ name: item.name, data: [item.area_stores] });
    });

    monthlyAverageData?.region_monthly_average?.map((item) => {
      graphValues.push({ name: item.name, data: [item.area_stores] });
    });

    monthlyAverageData?.zones_monthly_average?.map((item) => {
      graphValues.push({ name: item.name, data: [item.area_stores] });
    });

    setXAxis(categories);
    setGraphData(graphValues);
  }, [monthlyAverageData]);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      align: "center",
      text: "Zone-wise",
    },
    subtitle: {
      align: "left",
      text: "Region, Zones and Area",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      categories: xAxis,
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "NUmber of Radios switched ON",
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y} stores",
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> stores<br/>',
    },
    series: [...graphData],
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <Sidebar />
      <main className="content">
        <NavbarTop />
        <HighchartsReact highcharts={Highcharts} options={options} />
      </main>
    </>
  );
};
