import axios from "axios";

const apiInstance = axios.create({
  baseURL: "https://hondabe.radiowalla.in",
  // transformRequest: [(data) => JSON.stringify(data.data)],
  // credentials: "same-origin",
  headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default apiInstance;
