import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavbarTop } from "../../components/NavbarTop";
import "../../App.css";
import { Sidebar } from "../../components/Sidebar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import apiInstance from "../../utils/api";
import Exporting from "highcharts/modules/exporting";
import { accessToken } from "../../HelperFunction";
import Loaders from "./StoreDetails/Loaders";

export const ZoneWiseRegion = ({ overview }) => {
  drilldown(Highcharts);
  Exporting(Highcharts);

  const [zoneWiseSeriesData, setZoneWiseSeriesData] = useState([]);
  //console.log(zoneWiseSeriesData)
  const [zoneWiseSeriesDrillDownData, setZoneWiseSeriesDrillDownData] =
    useState([]);
   
  const [zoneWiseSeriesSingleData, setZoneWiseSeriesSingleData] = useState([]);


  const[isLoading,setIsLoading]=useState(true);
  
  useEffect(() => {
    apiInstance
      .get("/api/v1/stores/store-numbers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false)
          if (response.data.drilldown["series"].length !== 0) {
            setZoneWiseSeriesData(
              response.data.series.map((obj) => {
                return { ...obj, colorByPoint: true };
              })
            );
            setZoneWiseSeriesDrillDownData(response.data.drilldown["series"]);
          } else {
            setZoneWiseSeriesSingleData(
              response.data.series.map((obj) => {
                return { ...obj, colorByPoint: true };
              })
            );
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response.status === 401) {
          if (error.response.data.code === "token_not_valid") {
            accessToken();
          }
        }
      });
  }, []);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      align: "center",
      text: "Distribution Of Dealers",
    },
    subtitle: {
      align: "left",
      text: "",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Number of Dealers",
      },
      tickInterval: 1,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y} Dealers",
          shared: true,
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> dealer<br/>',
    },
    series: zoneWiseSeriesData,
    drilldown: {
      series: zoneWiseSeriesDrillDownData,
    },
    credits: {
      enabled: false,
    },
  };

  const optionsSingle = {
    chart: {
      type: "column",
    },
    title: {
      align: "center",
      text: "Total number of Dealers",
    },
    subtitle: {
      align: "left",
      text: "Region, Zones and Area",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "NUmber of Radios switched ON",
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y} stores",
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> stores<br/>',
    },
    series: zoneWiseSeriesSingleData,
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      {overview === "overview" ? (
        <>
          <div className="bg-card-shadow">
            <Card>
              {zoneWiseSeriesData.length === 0 ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsSingle}
                />
              ) : (
                <HighchartsReact highcharts={Highcharts} options={options} />
              )}
            </Card>
          </div>
        </>
      ) : (
        <>
          <Sidebar />
          {
            isLoading?<Loaders/> :  <main className="content">
            <NavbarTop />
            <Container>
              <Row>
                <Col>
                  <div className="bg-card-shadow">
                    <Card>
                      {zoneWiseSeriesData.length === 0 ? (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={optionsSingle}
                        />
                      ) : (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                        />
                      )}
                    </Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </main>
          }
         
        </>
      )}
    </>
  );
};
