import React, { useState, useEffect } from "react";
import "./Login.css";
import { Formik, Form, Field } from "formik";
import apiInstance from "../../utils/api";
import { Button, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const Login = () => {
  let navigate = useNavigate();

  const [errorMsg, setErrorMessage] = useState("");

  useEffect(() => {
    let auth = localStorage.getItem("isAuthenticated");
    if (auth) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validate = Yup.object({
    password: Yup.string().required(),
    email: Yup.string().required(),
  });

  const handleLoginSubmit = async (values) => {
    await apiInstance
      .post(`/api/v1/login`, {
        username: values.username,
        password: values.password,
      })
      .then((response) => {
        if (response.status === 200) {
          setErrorMessage("");
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("access", response.data.payload.access);
          localStorage.setItem("refresh", response.data.payload.refresh);
          localStorage.setItem("user_type", response.data.payload.user_type);
          localStorage.setItem("user_id", response.data.payload.user_id);
          localStorage.setItem("full_name", response.data.payload.full_name);
          localStorage.setItem("email", response.data.payload.email);
          navigate("/home");
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setErrorMessage(
            "Something went wrong. Please try again after sometime."
          );
        } else {
          setErrorMessage(error.response.data.errors[0].message);
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleLoginSubmit}
      >
        {({ values }) => (
          <div className="login-box-container">
            <div className="login-box">
              <h2>Login</h2>
              <Form>
                <div className="user-box">
                  <Field
                    type="text"
                    name="username"
                    required
                    autoComplete="off"
                  />
                  <label>Username</label>
                </div>
                <div className="user-box">
                  <Field
                    type="password"
                    name="password"
                    required
                    autoComplete="off"
                  />
                  <label>Password</label>
                </div>

                <div className="error-msg p-2">{errorMsg}</div>

                <Row>
                  <Col className="forgot-pass">
                    <Link to="/forgot-password">Forgot Password ?</Link>
                  </Col>
                </Row>

                <Button
                  type="submit"
                  className="login-form-submit-but"
                  onClick={() => handleLoginSubmit(values)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
