import apiInstance from "./utils/api";

export const accessToken = async function getAccessToken() {
  await apiInstance
    .post("/api/v1/token/refresh", {
      refresh: `${localStorage.getItem("refresh")}`,
    })
    .then((response) => {
      localStorage.removeItem("access");
      localStorage.setItem("access", response.data.access);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        if (error.response.data.error_type === "InvalidToken") {
          if (
            error.response.data.errors[0].message ===
            "Token is invalid or expired"
          ) {
            localStorage.clear();
            window.location = "/";
          }
        }
      }
    });
};
