import React, { useState, useEffect } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { Card, Col, Row, Table } from "react-bootstrap";
import { NavbarTop } from "../../../components/NavbarTop";
import apiInstance from "../../../utils/api";
import Loaders from "../StoreDetails/Loaders";
import { RiInformationLine } from "react-icons/ri";

export const MonthlySummary = () => {
  const [monthlySummaryData, setMonthlySummaryData] = useState([]);
  const [eventListsData, setEventListsData] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const getMonthlySummary = async () => {
      await apiInstance
        .get("api/v1/stores/monthly-summary", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setMonthlySummaryData(response.data);
          if (response) {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };

    const getEventList = async () => {
      await apiInstance
        .get("api/v1/stores/events-list", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setEventListsData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };

    getMonthlySummary();
    getEventList();
  }, []);

  return (
    <>
      <Sidebar />
      {isloading ? (
        <Loaders />
      ) : (
        <main className="content">
          <NavbarTop />
          {Object.entries(monthlySummaryData).length === 0 ? null : (
            <Row>
              <Col xs={12} md={6}>
                <div className="bg-card-shadow" style={{ height: 100 + "px" }}>
                  <Card
                    className="text-center color_plate_1"
                    style={{ height: 110 + "px", color: "white" }}
                  >
                    <Card.Body>
                      <span
                        className="card-info-icon"
                        style={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "flex-end",
                          color: "skyBlue",
                          fontSize: "20px",
                        }}
                      >
                        <RiInformationLine />
                        <span className="info-icon-text">
                          This data is aggregation of previous month's data
                        </span>
                      </span>
                      <Card.Title>Total Number of Dealers</Card.Title>
                      <Card.Text>{monthlySummaryData?.total_stores}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <div className="bg-card-shadow">
                  <Card
                    className="text-center color_plate_2"
                    style={{ height: 110 + "px", color: "white" }}
                  >
                    <Card.Body>
                      <span
                        className="card-info-icon"
                        style={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "flex-end",
                          color: "skyBlue",
                          fontSize: "20px",
                        }}
                      >
                        <RiInformationLine />
                        <span className="info-icon-text">
                          This data is aggregation of previous month's data
                        </span>
                      </span>
                      <Card.Title>Total Players Installed</Card.Title>
                      <Card.Text>
                        {monthlySummaryData?.players_installed}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="bg-card-shadow ">
                  <Card
                    className="text-center color_plate_3"
                    style={{ height: 110 + "px", color: "white" }}
                  >
                    <Card.Body>
                      <span
                        className="card-info-icon"
                        style={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "flex-end",
                          color: "skyBlue",
                          fontSize: "20px",
                        }}
                      >
                        <RiInformationLine />
                        <span className="info-icon-text">
                          This data is aggregation of previous month's data
                        </span>
                      </span>
                      <Card.Title>Players Active</Card.Title>
                      <Card.Text>
                        {monthlySummaryData?.active_players}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} md={6} className="mb-4 ">
                <div className="bg-card-shadow">
                  <Card
                    className="text-center color_plate_4"
                    style={{ height: 110 + "px", color: "white" }}
                  >
                    <Card.Body>
                      <span
                        className="card-info-icon"
                        style={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "flex-end",
                          color: "skyBlue",
                          fontSize: "20px",
                        }}
                      >
                        <RiInformationLine />
                        <span className="info-icon-text">
                          This data is aggregation of previous month's data
                        </span>
                      </span>
                      <Card.Title>Total Playout (Hours)</Card.Title>
                      <Card.Text>
                        {monthlySummaryData?.total_play_out_hours}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="bg-card-shadow">
                  <Card
                    className="text-center color_plate_5"
                    style={{ height: 110 + "px", color: "white" }}
                  >
                    <Card.Body>
                      <span
                        className="card-info-icon"
                        style={{
                          display: "flex",
                          flexFlow: "row nowrap",
                          justifyContent: "flex-end",
                          color: "skyBlue",
                          fontSize: "20px",
                        }}
                      >
                        <RiInformationLine />
                        <span className="info-icon-text">
                          This data is aggregation of previous month's data
                        </span>
                      </span>

                      <Card.Title>Average Playout (HH:MM) </Card.Title>
                      <Card.Text>
                        {/* {monthlySummaryData?.average_play_out_hours} */}
                        {String(
                          Math.trunc(monthlySummaryData?.average_play_out_hours)
                        ).padStart(2, "0")}{" "}
                        :{" "}
                        {String(
                          Math.trunc(
                            (
                              monthlySummaryData?.average_play_out_hours % 1
                            ).toFixed(2) * 60
                          )
                        ).padStart(2, "0")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          )}

          <Table striped bordered hover className="my-5 " id="table_monthly">
            <thead>
              <tr>
                <th>Event Date</th>
                <th>Occassion</th>
              </tr>
            </thead>
            {eventListsData.map((item) => {
              return (
                <tbody key={item.name}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.event_date}</td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </main>
      )}
    </>
  );
};
