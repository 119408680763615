import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: true,
}

export const loadingSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    loading: (state, action) => {
        state.value = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {loading} = loadingSlice.actions

export default loadingSlice.reducer