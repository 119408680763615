import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const dealerSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    dealerDetailsData: (state, action) => {
      state.value = action.payload
    },
    HeaderData: (state, action) => {
        state.value = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {dealerDetailsData ,HeaderData} = dealerSlice.actions

export default dealerSlice.reducer