import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import { SelectColumnFilter } from "../StoreDetails/filter";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CSVLink } from "react-csv";
import Loaders from "../StoreDetails/Loaders";
import Perform from "../StoreDetails/Perform";
export const WorstPerformance = ({ overview }) => {
  const [worstPerformanceData, setWorstPerformanceData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const[isLoading,setIsLoading]=useState(true);
  

  useEffect(() => {
    const getWorstPerformanceStoresData = async () => {
      await apiInstance
        .get("/api/v1/stores/worst-performing-stores", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setWorstPerformanceData(response.data.result);
          if(response){
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    };
    getWorstPerformanceStoresData();
  }, []);

  useEffect(() => {
    console.log("worstPerformanceData : ", worstPerformanceData);
  }, [worstPerformanceData]);

  const columns = useMemo(
    () => [
      {
        Header: "S.no.",
        id: "row",
        Filter: SelectColumnFilter,
        disableFilters: true,
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },

      {
        Header: "Dealer Name",
        accessor: "store__name",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Dealer Code",
        accessor: "store__store_code",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "City",
        accessor: "store__city",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Zone",
        accessor: "store__zone__name",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Region",
        accessor: "store__region__name",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Category",
        accessor: "store__category",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Average Duration",
        accessor: "avg",
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
    ],
    []
  );

  const handleSelectedDate = (event, picker) => {
    setIsLoading(true);
    apiInstance
      .get(
        `/api/v1/stores/worst-performing-stores?start_date=${moment(
          picker.startDate
        ).format("YYYY-MM-DD")}&end_date=${moment(picker.endDate).format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        setWorstPerformanceData(response.data.result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const headers = [
    { label: "Dealer Name", key: "store__name" },
    { label: "Dealer Code", key: "store__store_code" },
    { label: "APP ID", key: "store__store_username" },
    { label: "Zone", key: "store__zone__name" },
    { label: "Region", key: "store__region__name" },
    { label: "Category", key: "store__category" },
    { label: "Average Duration", key: "avg" },
  ];

  const csvReport = {
    filename: "Low Performing Stores Report.csv",
    headers: headers,
    data: worstPerformanceData,
  };

  return (
    // <>
    //   {overview === "overview" ? (
    <>
      <Row>
        <Col md={5}>
          <DateRangePicker
            onApply={handleSelectedDate}
            initialSettings={{
              startDate: startDate,
              endDate: endDate,
              ranges: {
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "days"),
                  moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
              },
            }}
          >
            <input type="text" className="form-control col-4"></input>
          </DateRangePicker>
        </Col>
        <Col>
          <CSVLink
            {...csvReport}
            className="export-to-csv-button p-1 download_btn"
          >
            Download Report
          </CSVLink>
        </Col>
      </Row>
      <div className="my-4">
        {/* <div className="bg-card-shadow">
              <Card> */}
        <Perform mockData={worstPerformanceData} columns={columns} />
        {/* </Card>
            </div> */}
      </div>
    </>
    //     ) : (
    //       <>
    //         <Sidebar />
    //         {
    //           isLoading ? <Loaders/> : <main className="content">
    //           <NavbarTop />
    //           <Container>
    //             <h4 className="text-center mb-2 heading-h4-home">
    //               Low Performing Dealers
    //             </h4>
    //             <Row>
    //               <Col md={5}>
    //                 <DateRangePicker
    //                   onApply={handleSelectedDate}
    //                   initialSettings={{
    //                     startDate: startDate,
    //                     endDate: endDate,
    //                     ranges: {
    //                       Today: [moment(), moment()],
    //                       Yesterday: [
    //                         moment().subtract(1, "days"),
    //                         moment().subtract(1, "days"),
    //                       ],
    //                       "Last 7 Days": [moment().subtract(6, "days"), moment()],
    //                       "Last 30 Days": [
    //                         moment().subtract(29, "days"),
    //                         moment(),
    //                       ],
    //                       "This Month": [
    //                         moment().startOf("month"),
    //                         moment().endOf("month"),
    //                       ],
    //                       "Last Month": [
    //                         moment().subtract(1, "month").startOf("month"),
    //                         moment().subtract(1, "month").endOf("month"),
    //                       ],
    //                     },
    //                   }}
    //                 >
    //                   <input type="text" className="form-control col-4"></input>
    //                 </DateRangePicker>
    //               </Col>
    //               <Col>
    //                 <CSVLink {...csvReport} className="export-to-csv-button p-1 download_btn">
    //                   Download Report
    //                 </CSVLink>
    //               </Col>
    //             </Row>
    //             <div className="my-4">
    //               <div className="bg-card-shadow">
    //                 <Card>
    //           <Perform mockData={worstPerformanceData} columns={columns} />

    //               </Card>
    //               </div>
    //             </div>
    //           </Container>
    //         </main>
    //         }

    //       </>
    //     )}
    //   </>
  );
};
