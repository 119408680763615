import React, { useEffect, useState } from 'react'
import { useTable,useGlobalFilter,usePagination,useFilters,useSortBy } from 'react-table';
import { SelectColumnFilter } from '../StoreDetails/filter';
import GlobalFilter from '../StoreDetails/GlobalFilter';
import { Scrollbar } from 'react-scrollbars-custom';
import Popup from '../StoreDetails/Popup';
import {  useBlockLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Styles } from '../DayWiseReport/TableStyles';

 function OnDemandSpotTable(props) {
   const data = React.useMemo(() => [...props.mockData], [props.mockData]);
   const columns = React.useMemo(() => [...props.columns], [props.columns]);
   
   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,

     page,
     nextPage,
     previousPage,
     canPreviousPage,
     canNextPage,
     prepareRow,
     pageOptions,
     setPageSize,
     gotoPage,
     pageCount,
     state,
     setGlobalFilter,
   } = useTable(
     { columns, data },
     useFilters,
     useGlobalFilter,
     useSortBy,
     usePagination,
     useBlockLayout,
     useSticky
   );

   const { globalFilter, pageIndex, pageSize } = state;
   const generateSortingIndicator = (column) => {
     return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
   };

   const [_show, _setShow] = useState(false);
   const [row_data, set_rowData] = useState([]);

   const getId = (data) => {
     //console.log(data)
     var config = {
       method: "get",
       url: `https://hondabe.radiowalla.in/api/v1/stores/on-demand-analytics/details?date=${data?.column?.Header}&store_id=${data?.row?.original?.id}`,
       headers: {
         Authorization: `Bearer ${localStorage.getItem("access")}`,
       },
     };

     axios(config)
       .then(function (response) {
         //  console.log((response.data));
         set_rowData(response.data);
       })
       .catch(function (error) {
         console.log(error);
       });
     _setShow(true);
   };

   const _handleClose = () => _setShow(false);
   //const _handleShow = () => _setShow(true);

   var axios = require("axios");

   //  const changeOrder =()=>{
   //   setColumnOrder(["","","","","2022-12-07","2022-12-06"])
   //  }

   return (
     <>
       {/* <button onClick={changeOrder}>Order</button> */}
       <span className="_page_limit">
         <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
         <span className="fw-bold">
           Show Results:{" "}
           <select
             value={pageSize}
             onChange={(e) => setPageSize(e.target.value)}
             id="page_limit"
           >
             {[10, 20, 30, 40, 50].map((pageSize) => {
               return (
                 <option key={pageSize} value={pageSize}>
                   {pageSize}
                 </option>
               );
             })}
           </select>
         </span>
       </span>
       <div style={{ overflowX: "auto" }}>
         <Popup
           _show={_show}
           handleClose={_handleClose}
           dealer={row_data.dealer_name}
           additionalData={row_data?.data}
         />
         <Styles>
           <table
             {...getTableProps()}
             className="table sticky"
             id="onDemandSpot_table"
           >
             <thead className="header">
               {Object.values(headerGroups)?.map((headerGroup) => (
                 <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                   {headerGroup?.headers?.map((column) => (
                     <th
                       className="th"
                       {...column?.getHeaderProps(
                         column.getSortByToggleProps()
                       )}
                     >
                       {column?.render("Header")}
                       {generateSortingIndicator(column)}
                       <div>
                         {column.canFilter ? column.render("Filter") : null}
                       </div>
                     </th>
                   ))}
                 </tr>
               ))}
             </thead>
             <tbody {...getTableBodyProps()} className="body">
               {page.map((row) => {
                 prepareRow(row);
                 return (
                   <tr {...row.getRowProps()} className="tr">
                     {row?.cells?.map((cell) => {
                       return (
                         <td
                           className="td"
                           {...cell.getCellProps()}
                           onClick={() => {
                             getId(cell);
                           }}
                           //  style={{
                           //    padding: '10px',
                           //    border: 'solid 1px gray',
                           //    background: 'papayawhip',
                           //  }}
                         >
                           {cell.render("Cell")}
                         </td>
                       );
                     })}
                   </tr>
                 );
               })}
             </tbody>
           </table>
         </Styles>
       </div>
       <div className="pagination_btn ">
         <span className="mt-2 me-2">
           <span className="">Page </span>
           <strong>
             <span className="text-success">{pageIndex + 1}</span> of{" "}
             {pageOptions.length}
           </strong>
         </span>
         <div className="button_flex">
           <button
             onClick={() => gotoPage(0)}
             disabled={!canPreviousPage}
             className={canPreviousPage ? "_buttonNext" : "buttonPrev"}
           >
             {"<<"}
           </button>
           <button
             onClick={() => previousPage()}
             disabled={!canPreviousPage}
             className={canPreviousPage ? "_buttonNext" : "buttonPrev"}
           >
             Prev
           </button>
           <button
             onClick={() => nextPage()}
             disabled={!canNextPage}
             className={canNextPage ? "_buttonNext" : "buttonPrev"}
           >
             Next
           </button>
           <button
             onClick={() => gotoPage(pageCount - 1)}
             disabled={!canNextPage}
             className={canNextPage ? "_buttonNext" : "buttonPrev"}
           >
             {">>"}
           </button>
         </div>
       </div>
     </>
   );
 }
 export default OnDemandSpotTable