import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const filter = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    filterData: (state, action) => {
        state.value = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {filterData} = filter.actions

export default filter.reducer