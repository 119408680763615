import React, { useEffect, useState, useMemo } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import moment from "moment";
import { CSVLink } from "react-csv";
import { SelectColumnFilter } from "../StoreDetails/filter";
import DetailWiseReportTable from "../StoreDetails/DetailWiseReportTable";
import Loaders from "../StoreDetails/Loaders";
import { useSelector, useDispatch} from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { loading } from "../../../redux/slices/loadingSlice";
export let handleShow;

export const RealTimeStores = () => {
  const _loading=useSelector(state=>state?.loadingSlice?.value);
  console.log(_loading)
  const dispatch=useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => 
  {setShow(false)
    dispatch(loading(true));
  };
   handleShow = () => setShow(true);
  const t_data=useSelector(state=>state.real_time_data.value);
  console.log(t_data?.output)
  //-----------------------//
  const [tableData, setTableData] = useState([]);
  const[isLoading,setIsLoading]=useState(true);
 
  let newArray=[];
  newArray.push(t_data?.output?.timestamp);
  t_data?.output?.map((v)=>{
     newArray.push({timestamp:moment(parseInt( v.timestamp))?.format("MMM Do YY, h:mm:ss a"),song:v.song,duration:v.duration < 59 ? `0m ${v.duration  < 10 ? `${v.duration}` : v.duration}s`: `${Math.floor(v.duration / 60) <10 ? `${Math.floor(v.duration / 60)}m` : Math.floor(v.duration / 60) }  ${ v.duration % 60 < 10 ? `0${v.duration % 60}` : v.duration % 60}s`})
  })
  
  useEffect(() => {
    const getRealTimeStoresData = async () => {
      await apiInstance
        .get("/api/v1/stores/real-time-analytics", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          const storeDetails = response.data;
          // console.log("storeDetails", storeDetails)
          storeDetails.map((store) => {
                if(store.downloader_last_sync && store.downloader_last_sync != 0) {
                  store.downloader_last_sync = moment(store.downloader_last_sync).format("MMM Do YY, h:mm:ss a")
                } else {
                  console.log("Store name " + store.store.name)
                  store.downloader_last_sync = "--"
                }
                if(store.npt && store.npt != 0) {
                  store.npt = moment(store.npt).format("MMM Do YY, h:mm:ss a")
                } else {
                  store.npt = "--"
                }
          })
          setTableData(storeDetails);
          if(response){
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    };
    getRealTimeStoresData();
  }, []);

  // useEffect(()=>{
  //   const getRealTimeTrackData = async () => {
  //     await apiInstance
  //       .get("/api/v1/stores/real-time-d-l/t39758542/15-12-2022 ", {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access")}`,
  //         },
  //       })
  //       .then((response) => {
  //        console.log(response)
  //        setRealTime( response.data?.output?.map((v)=>{
  //         return moment(parseInt(v?.timestamp)).format("MMM Do YY, h:mm:ss a")
  //         }))
  //         if(response){
  //           setIsLoading(false)
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsLoading(false)
  //       });
  //   };
  //   getRealTimeTrackData();
  // },[])

  const columns = useMemo(
    () => [
      {
        Header: "S.no.",
        id: "row",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
        width: 60,
        sticky: "left",
        disableGlobalFilter: true,
        // filterable: false,
        // canFilter: false,
      },
      {
        Header: "Dealer Name",
        accessor: "store.name",
        sticky: "left",
        Filter: SelectColumnFilter,
        disableFilters: true,
        //filter: "equals",
      },
      {
        Header: "Dealer Code ",
        accessor: "store.store_code",
        sticky: "left",
        Filter: SelectColumnFilter,
        disableFilters: true,
        //filter: "equals",
      },
      {
        Header: "APP ID",
        accessor: "store.store_username",
        sticky: "left",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "City",
        accessor: "store.city",
        sticky: "left",
        Filter: SelectColumnFilter,
        disableFilters: true,
        //filter: "equals",
      },
      {
        Header: "Zone",
        accessor: "store.zone_name",
        sticky: "left",
        Filter: SelectColumnFilter,
        disableFilters: true,
        // filter: "equals",
      },
      // {
      //   Header: "Real Time Logs",
      //   accessor: "real_time_logs",
      //   sticky:'left',
      //   Cell: () => {
      //     //console.log(props.value)
      //     return (
      //       <span ></span>
      //     );
      //   },
      //   Filter: SelectColumnFilter,
      //   disableFilters: true,
      //  // filter: "equals",
      // },
      {
        Header: "Region",
        accessor: "store.region_name",
        Filter: SelectColumnFilter,
        disableFilters: true,
        //filter: "equals",
      },
      {
        Header: "Category",
        accessor: "store.category",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Tracks",
        accessor: "total_tracks",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Imaging",
        accessor: "total_imaging",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Spots",
        accessor: "total_spots",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Last Synced At",
        accessor: "downloader_last_sync",
        // Cell: (props) => {
        //   // console.log("props " + typeof(props.value))
        //   return (
        //     props.value == 0? "--" : <span>{moment(props.value).format("MMM Do YY, h:mm:ss a")}</span>
        //   );
        // },
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Now Playing",
        accessor: "now_playing",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Last pinged At",
        accessor: "npt",
        // Cell: (props) => {
        //   return (
        //     props.value == 0? "--" : <span>{moment(props.value).format("MMM Do YY, h:mm:ss a")}</span>
        //   );
        // },
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Last Known IP",
        accessor: "ip_address",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "PLG Downloaded",
        accessor: "plg_name",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Player Version:",
        accessor: "version",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "Downloader Version:",
        accessor: "download_version",
        Filter: SelectColumnFilter,
        disableFilters: true,
      },
      {
        Header: "As Played Log",
        // accessor: "as_played_log",
        disableGlobalFilter: true,
        // filterable: false,
        // canFilter: false,
        // canSort: false,
      },
    ],
    []
  );

  const headers = [
    { label: "Store Username", key: "store.store_username" },
    { label: "Store Code", key: "store.store_code" },
    { label: "Dealer Name", key: "store.name" },
    { label: "Zone", key: "store.zone_name" },
    { label: "Tracks", key: "total_tracks" },
    { label: "Imaging", key: "total_imaging" },
    { label: "Spots", key: "total_spots" },
    { label: "Last Synced At", key: "downloader_last_sync" },
    { label: "Now Playing", key: "now_playing" },
    { label: "Last pinged At", key: "npt" },
    { label: "Last Known IP", key: "ip_address" },
    { label: "PLG Downloaded", key: "nptd" },
    { label: "Player Version", key: "version" },
    { label: "Downloader Version", key: "download_version" },
  ];

  const header2=[
    {label: "Time/Date", key: "timestamp"},
    {label: "Track Name", key: "song"},
    {label: "Duration", key: "duration"},

  ]

  const csvReport = {
    filename: "Real Time Stores Report.csv",
    headers: headers,
    data: tableData,
  };
  const csvReport2 = {
    filename: "Real Time Tracks Report.csv",
    headers: header2,
    data: newArray.slice(1),
  };

  return (
    <>
      <Sidebar />
      {isLoading ? <Loaders/> : <main className="content">
        <NavbarTop />
        <Container>
          <h4 className="text-center mb-2 heading-h4-home mb-5">Real-Time Data</h4>
          <Row>
            <Col>
              <CSVLink {...csvReport} className="export-to-csv-button p-1 download_btn">
                Download Report
              </CSVLink>
            </Col>
          </Row>
          <span>
         
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Real Time Logs</Modal.Title>
        </Modal.Header>
        {
          _loading ? <div className="text-cemter text-danger fw-bold p-4">Please Wait While Loading...</div> :  t_data?.Error  ? <Modal.Body>
          No Logs Found
        </Modal.Body> : <Modal.Body style={{maxHeight:'450px',overflow:'auto'}}>
          
        <Row>
            <Col>
              <CSVLink {...csvReport2} className="export-to-csv-button p-1 download_btn">
                Download Report
              </CSVLink>
            </Col>
        </Row>
        <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            
            <th>Time/Date</th>
            <th>Track Name</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
         
  
        {
          (t_data?.output)?.map((v)=>{
            console.log("track_date_value ", v)
            console.log("track_date_type ", typeof(v.timestamp))
            return  <tr>
             <td>{moment(parseInt( v.timestamp)).format("MMM Do YY, h:mm:ss a")}</td>
             <td>{v.song}</td>
             <td>{v.duration < 59 ? `0m ${v.duration  < 10 ? `${v.duration}` : v.duration}s`: `${Math.floor(v.duration / 60) <10 ? `${Math.floor(v.duration / 60)}m` : Math.floor(v.duration / 60) }  ${ v.duration % 60 < 10 ? `0${v.duration % 60}` : v.duration % 60}s`}</td>
             
             </tr>
          })
        }
            
        </tbody>
      </Table>
          </Modal.Body>
        }
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          </span>
          <div className="my-3">
            {/* <TableContainer columns={columns} data={tableData} /> */}
            <DetailWiseReportTable
            columns={columns}
            mockData={tableData}
            />
          </div>
        </Container>
      </main> }
     
    </>
  );
};
