import { configureStore } from '@reduxjs/toolkit'
import  dealerSlice  from './slices/dealerDetails';
import categoryHeader from './slices/categoryHeader';
import filter from './slices/filterData';
import real_time_data from './slices/realtimeSlice';
import loadingSlice from './slices/loadingSlice';
export const store = configureStore({
  reducer: {
    dealerSlice,
    categoryHeader,
    filter,
    real_time_data,
    loadingSlice

  },
})