import React, { useEffect, useState } from "react";
import '../../../components/commoncss/common.css';
import { Container, Row, Col, Modal, Table } from "react-bootstrap";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Sidebar } from "../../../components/Sidebar";
import apiInstance from "../../../utils/api";
import TableContainer from "../StoreDetails/TableContainer";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { SelectColumnFilter } from "../StoreDetails/filter";
import DWreport from "./DWreport";
import Loaders from "../StoreDetails/Loaders";
import { CSVLink } from "react-csv";

export const DayWiseReport = () => {
  const [dayWiseReportsData, setDayWiseReportsData] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [dcfdC, setDC] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [rowID, setRowID] = useState("");
  const [cellDate, setCellDate] = useState("");
  const [additionalDealerName, setAdditionalDealerName] = useState("");
  const [show, setShow] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [columns, setColumns] = useState([
    {
      Header: "S.No.",
      id: "row",
      Cell: ({ row }) => <span>{row?.index + 1}</span>,
      width: 60,
      sticky: "left",
      disableGlobalFilter: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "STORE_CODE ",
      accessor: "store_code",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "CATEGORY",
      accessor: "category",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "NETWORK_TYPE",
      accessor: "network_type",
      sticky: "left",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
  ]);

  const handleClose = () => {
    setAdditionalData([]);
    setAdditionalDealerName("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [state, setState] = useState(moment().subtract(6, "days"));

  const handleCallback = (start, end) => {
    setState({ start, end });
  };

  useEffect(() => {
    const getStoreData = async () => {
      await apiInstance
        .get("/api/v1/stores/store-daily-play-out", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          let tmp = response.data;
          if (response) {
            setIsLoading(false);
          }

          tmp.forEach((e) => {
            delete e.store__id;
            delete e.category;
          });

          let dColumns = Object.keys(tmp[0])?.map((key) => {
            return {
              Header: key.split("_").join(" "),
              accessor: key,
              Filter: SelectColumnFilter,
              filter: "equals",
              Cell: (props) => {
                return (
                  <p style={{ color: props.value !== "0" ? "null" : "red" }}>
                    {props.value}
                  </p>
                );
              },
            };
          });

          const serialNumber = {
            Header: "S.no.",
            id: "row",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
          };

          for (let project of tmp) {
            if (project.network_type === "3") {
              project.network_type = "AD-3rd Party";
            } else if (project.network_type === "1") {
              project.network_type = "AMD";
            }
          }

          dColumns.map((item) => {
            if (item.Header === "app_id") {
              item["show"] = false;
              return item;
            }
          });

          const newColumns = Object.keys(tmp[0]).map((key, index) => {
            if (index < 4) {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                sticky: "left",
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            } else {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            }
          });

          newColumns.unshift({
            Header: "S.no.",
            id: "row",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
            width: 60,
            sticky: "left",
            disableGlobalFilter: true,
          });

          console.log("newColumns : ", newColumns);
          setColumns(newColumns);

          setDC([serialNumber, ...dColumns]);
          setDayWiseReportsData(tmp);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
    getStoreData();
  }, []);

  const handleSelectedDate = (event, picker) => {
    setIsLoading(true);
    apiInstance
      .get(
        `/api/v1/stores/store-daily-play-out?start_date=${moment(
          picker.startDate
        ).format("YYYY-MM-DD")}&end_date=${moment(picker.endDate).format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      )
      .then((response) => {
        let tmp = response.data;
        if (response.status) {
          setIsLoading(false);
        }

        tmp.forEach((e) => {
          delete e.store__id;
          delete e.category;
        });

        let dColumns = Object.keys(tmp[0])?.map((key) => {
          return {
            Header: key.split("_").join(" "),
            accessor: key,
            Filter: SelectColumnFilter,
            filter: "equals",
            Cell: (props) => {
              return (
                <p style={{ color: props.value !== "0" ? "null" : "red" }}>
                  {props.value}
                </p>
              );
            },
          };
        });

        const serialNumber = {
          Header: "S.no.",
          id: "row",
          Cell: ({ row }) => <span>{row.index + 1}</span>,
        };

        for (let project of tmp) {
          if (project.network_type === "3") {
            project.network_type = "AD-3rd Party";
          } else if (project.network_type === "1") {
            project.network_type = "AMD";
          }
        }

        dColumns.map((item) => {
          if (item.Header === "app_id") {
            item["show"] = false;
            return item;
          }
        });

        console.log("tmp : ", tmp);

        if (tmp) {
          const newColumns = Object.keys(tmp[0]).map((key, index) => {
            if (index < 4) {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                sticky: "left",
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            } else {
              return {
                Header: key.toUpperCase(),
                accessor: key,
                Filter: SelectColumnFilter,
                disableFilters: true,
              };
            }
          });

          newColumns.unshift({
            Header: "S. No.",
            id: "row",
            Cell: ({ row }) => <span>{row.index + 1}</span>,
            width: 60,
            sticky: "left",
            disableGlobalFilter: true,
          });

          console.log("newColumns : ", newColumns);
          setColumns(newColumns);
        }

        setDC([serialNumber, ...dColumns]);
        setDayWiseReportsData(tmp);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const csvReport = {
    filename: "Day Wise Report.csv",
    // headers: headers,
    data: dayWiseReportsData,
  };

  // const handleOnDemandClick = (rowID, cellDate) => {
  //   console.log(rowID,cellDate)
  //   handleShow();
  //   apiInstance
  //     .get(
  //       `/api/v1/stores/store-daily-play-out/details?store_username=${rowID}&date=${cellDate}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("access")}`,
  //         },
  //       }
  //     )
  //     .then((re) => {
  //       setAdditionalData(re.data);
  //       console.log(re)
  //       // setAdditionalDealerName(re.data);
  //     })
  //     .catch((er) => {
  //       console.log(er);
  //     });
  // };

  return (
    <div className="mb-3">
      <Sidebar />
      {isLoading ? (
        <Loaders />
      ) : (
        <main className="content">
          <NavbarTop />
          <Container>
            <h4 className="text-center mb-5 heading-h4-home">
              Day-wise Reports
            </h4>
            <Row>
              <Col>
                <CSVLink
                  {...csvReport}
                  className="export-to-csv-button p-1 download_btn"
                >
                  Download Report
                </CSVLink>
              </Col>
            </Row>
            <Row>
              <Col md={5} className=" ">
                <DateRangePicker
                  onCallback={handleCallback}
                  onApply={handleSelectedDate}
                  initialSettings={{
                    startDate: state?.start?.format("MM/DD/YYYY (dddd)"),
                    endDate: state?.end?.format("MM/DD/YYYY (dddd)"),
                    ranges: {
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                    },
                  }}
                >
                  <input
                    type="text"
                    className="form-control col-4  "
                    style={{ maxWidth: "300px" }}
                  />
                </DateRangePicker>
                {/* <input type='text' placeholder="search..." value={searchResult} onChange={(e)=>setSearchResult(e.target.value)}/> */}
              </Col>
            </Row>

            <div className="my-4">
              {/* <TableContainer
              columns={dcfdC}
              data={dayWiseReportsData}
              changeID={(rowID) => setRowID(rowID)}
              changeCellDate={(cellDate) => setCellDate(cellDate)}
              onclick={handleOnDemandClick}
              dayWise="true"
            /> */}
              <DWreport mockData={dayWiseReportsData} columns={columns} />
              <p style={{ color: "red" }}>
                **Note: Missing Data should be treated as 0 playout or Dealer
                was closed.
              </p>
            </div>

            {/* <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-dialog-scrollable"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                {additionalDealerName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>As Played</th>
                    <th>Played At Date-Time</th>
                    <th>Duration</th>                    
                  </tr>
                </thead>
                {additionalData.length !== 0 ? (
                  <>
                    {additionalData?.map((item, index) => {
                      if (item.length === 0) {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        );
                      } else {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.as_played}</td>
                              <td>
                                {moment(item.played_at_time).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </td>
                              <td>{item.duration}</td>
                            </tr>
                          </tbody>
                        );
                      }
                    })}
                  </>
                ) : null}
              </Table>
            </Modal.Body>
          </Modal> */}
          </Container>
        </main>
      )}
    </div>
  );
};
