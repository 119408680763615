import React, { useEffect, useState } from "react";
import { Nav, Navbar, Dropdown, Container } from "react-bootstrap";
import { RiLogoutCircleRLine, RiUser2Fill } from "react-icons/ri";
import { useNavigate } from "react-router";
import { FaUserAlt, FaUserLock } from "react-icons/fa";

export const NavbarTop = () => {
  const navigate = useNavigate();

  const [userAccessLevel, setUserAccessLevel] = useState("");

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleUserProfilePage = () => {
    navigate("/my-profile");
  };

  useEffect(() => {
    if (localStorage.getItem("user_type") === "1") {
      setUserAccessLevel("Head Office Manager");
    } else if (localStorage.getItem("user_type") === "2") {
      setUserAccessLevel("Regional Manager");
    } else if (localStorage.getItem("user_type") === "3") {
      setUserAccessLevel("Zonal Manager");
    } else if (localStorage.getItem("user_type") === "4") {
      setUserAccessLevel("Area Manager");
    }
  }, []);

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
        
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block navbar_username">
                    <FaUserAlt className="mx-2 user_icon"  />
                    <span className="mb-0 font-small profile_name">
                      {localStorage.getItem("full_name")}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="user-dropdown dropdown-menu-right mt-2"
                style={{ margin: "0px" }}
              >
                <Dropdown.Item
                  className="fw-bold"
                  onClick={handleUserProfilePage}
                >
                  <RiUser2Fill className="mx-2" />
                  My Profile
                </Dropdown.Item>

                <Dropdown.Item
                  className="fw-bold"
                  onClick={handleUserProfilePage}
                >
                  <FaUserLock className="mx-2" />
                  <span>{userAccessLevel}</span>
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={handleLogOut}>
                  <RiLogoutCircleRLine className="mx-2 logout-ico" />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
