import React, { useEffect, useState, useMemo } from "react";
import { Sidebar } from "../../../components/Sidebar";
import { NavbarTop } from "../../../components/NavbarTop";
import "../../../App.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import apiInstance from "../../../utils/api";

import { OnlineOfflineStoresGraph } from "./OnlineOfflineStoresGraph";

const OnlineOfflineStores = () => {
  const [storesData, setStoresData] = useState([]);

  useEffect(() => {
    const getStoresData = async () => {
      await apiInstance
        .get("/api/v1/music/online-offline-stores", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          setStoresData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getStoresData();
  }, []);

  return (
    <>
      <Sidebar />
      <main className="content">
        <NavbarTop />
        <Container>
          <Row className="mb-5">
              <h3 className="text-center m2-10 heading-h4-home">Online-Offline Dealers</h3>
          </Row>
          <Row>
            <Col>
              <OnlineOfflineStoresGraph />
            </Col>
            <Col>
              <h5 className="text-center m-5 heading-h4-home"> {storesData.realtime} Dealers Online </h5>
              <h5 className="text-center m-5 heading-h4-home"> {storesData.total - storesData.realtime} Dealers Offline </h5>
            </Col>
          </Row>
            
          
        </Container>
      </main>
    </>
  );
}

export default OnlineOfflineStores